import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { ModalInputWrapper as InputWrapper, ModalButtonContainer as ButtonContainer } from '../..';
import { countries_ } from '../../../../mocks';
import { Location, Department } from '../../interfaces';

interface AddNewLocationModal {
    onSave: (location: Location) => void;
    locations: Location[];
    departments: Department[];
}

const AddNewLocationModal: React.FC<AddNewLocationModal> = ({ onSave, locations, departments }) => {

    const [locationName, setLocationName] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState(countries_[0].value);
    const [description, setDescription] = useState('');
    const [department, setDepartment] = useState(departments?.[0]?.name);

    const [error, setError] = useState('')


    const onLocationInputChange = (value: string) => {
        setError('')
        setLocationName(value)
    }

    const save = () => {
        setError('')

        if (!locationName) {
            setError('Please insert location name')
            return
        }

        if (locations?.find(({ name }) => name === locationName)) {
            setError('Please insert unique location name')
            return
        }

        const location = {
            name: locationName,
            country,
            department,
            address,
            details: description,
        }


        console.log({ location })
        onSave(location)
    }

    const departmentsOptions = departments?.map(({ name }) => {
        return {
            value: name,
            label: name
        }
    })

  return (
    <>      
      <Title>Create new item</Title>
      <div>
        <InputWrapper >
            <label htmlFor='name'>Location name</label>
            <Input name='name' value={locationName} onChange={onLocationInputChange} error={error} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='address'>Address</label>
            <Input name='address' value={address} onChange={(value) => setAddress(value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='country'>Country</label>
            <Select options={countries_} name='country' required value={country || ''} onChange={(e) => setCountry(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='department'>Department</label>
            <Select options={departmentsOptions || []} name='department' value={department} required onChange={(e) => setDepartment(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='description'>Description</label>
            <Input name='desc' textarea value={description} onChange={(value) => setDescription(value)} />
        </InputWrapper>
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewLocationModal;