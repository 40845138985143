import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Content, Tab, Tabs } from '../../App';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { colors, TABS } from '../../constants';
import { Category, Item, Location, UserRole, User } from './interfaces';
import CompanyProfile from './Company';
import Departments from './Departments';
import ProductCategories from './ProductCategories';
import Items  from './Items';
import Locations from './Locations';
import UserRoles from './UserRoles';
import Users from './Users';
import { useSupplier } from '../../context/SupplierContext';

export const RoundButtonContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: end;
`

export const Title = styled.h3`
    color: ${colors.darkGreen};
    margin-bottom: 20px;
    margin-top: 0;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    max-width: 300px;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    > button {
        margin-bottom: 10px;
    }
`;

export const ModalInputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 10px;

    > label {
        width: 200px;
        padding-top: 10px;
    }

    > div {
        margin-bottom: 0;
        width: 100%;
    }
`;

export const ModalButtonContainer = styled.div`
    margin-top: 30px;
`;

const Settings: React.FC = () => {
  const [tab, setTab] = useState('company');

  const [categories, setCategories] = useState<Category[]>([])
  const [items, setItems] = useState<Item[]>([])
  const [locations, setLocations] = useState<Location[]>([])

  const { departments, setDepartments } = useSupplier()

  const [userRoles, setUserRoles] = useState<UserRole[]>([])
  const [users, setUsers] = useState<User[]>([])

  const updateCategoryTotal = (catName: string) => {
    const index = categories.findIndex(cat => cat.name === catName)
    categories[index].totalItems += 1
    setCategories([...categories])
  }

  const updateUsersTotal = (userRole: string) => {
    const index = userRoles.findIndex(role => role.name === userRole)
    userRoles[index].totalUsers += 1
    setUserRoles([...userRoles])
  }

  console.log({  departments })
  const tabConfig = [
    {
      label: "Company",
      key: "company",
      component: <CompanyProfile />
    },
    {
      label: "Departments",
      key: "departments",
      component: <Departments setDepartments={setDepartments} departments={departments} /> 
    },
    {
        label: "Product Categories",
        key: "productCategories",
        component: <ProductCategories categories={categories} setCategories={setCategories}/>
    },
    {
        label: "Items",
        key: "items",
        component: <Items categories={categories} items={items} setItems={setItems} updateCategoryTotal={updateCategoryTotal}/> 
    },
    {
        label: "Locations",
        key: "locations",
        component: <Locations locations={locations} setLocations={setLocations} departments={departments} /> 
    },
    {
        label: "User roles",
        key: "userRoles",
        component: <UserRoles userRoles={userRoles} setUserRoles={setUserRoles} /> 
    },
    {
        label: "Users",
        key: "users",
        component: <Users userRoles={userRoles} setUsers={setUsers} users={users} departments={departments} updateUserRolesTotal={updateUsersTotal} /> 
    },
  ];

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.profile} />

                <Content>
                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setTab(tabItem.key)}
                                active={tab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>

                    { tabConfig.find(({ key }) => key === tab)?.component }

                </Content>
            </Container>
        </>
    );
};

export default Settings;
