import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

interface StyledInputProps {
    value?: string | number;
    onChange?: (value: string) => void;
    onUpload?: (files: File[]) => void;
    name: string;
    label?: string;
    required?: boolean;
    type?: 'text' | 'password' | 'email' | 'date' | 'file'| 'number';
    textarea?: boolean;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    labelToLeft?: boolean;
    style?: React.CSSProperties;
    multiple?: boolean;
    error?: string;
}

const StyledInput = styled.input<{ error: boolean }>`
  width: 100%;
  padding: 7px;
  font-size: 14px;
  border: 1px solid ${({ error }) => error ? 'red' : colors.mediumGreen};
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: Poppins, Arial, Helvetica, sans-serif;

  &:focus {
    border-color: ${colors.darkGreen};
    box-shadow: 0 0 5px rgba(25, 105, 25, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: #f7f7f7;
    border-color: #e0e0e0;
  }
`;

const StyledTextarea = styled.textarea<{ error: boolean }>`
  width: 100%;
  padding: 7px;
  font-size: 14px;
  border: 1px solid ${({ error }) => error ? 'red' : colors.mediumGreen};
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  resize: vertical; /* Allow vertical resizing */
  font-family: Poppins, Arial, Helvetica, sans-serif;

  &:focus {
    border-color: ${colors.darkGreen};
    box-shadow: 0 0 5px rgba(25, 105, 25, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: #f7f7f7;
    border-color: #e0e0e0;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const Error = styled.div`
  color: red;
`

const Input: React.FC<StyledInputProps> = ({
  placeholder,
  disabled,
  label,
  type,
  textarea,
  name,
  value,
  onChange,
  onUpload,
  required,
  maxLength,
  multiple,
  error,
  style
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (type === 'file') {
      const { files } = e.target as HTMLInputElement;
      if (onUpload && files) onUpload(Array.from(files));
    } else {
      const { value } = e.target;
      if (onChange) onChange(value);
    }
  };

  return (
    <FormGroup style={style}>
        {label && <Label htmlFor={name}>{label}</Label>}
        {textarea  ? (
          <StyledTextarea
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            rows={4}
            maxLength={maxLength}
            error={!!error}
          />
        ) : (
          <StyledInput
            multiple={multiple}
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            error={!!error}

          />
        )}
        { error && <Error>{error}</Error> }
    </FormGroup>
  );
};

export default Input;
