import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewLocationModal from './Modal';
import { Location, Department } from '../interfaces';
import { RoundButtonContainer } from '..';
import { Content } from '../../../App';

interface Props {
  locations: Location[];
  setLocations: (locations: Location[]) => void;
  departments: Department[];
}

const Locations: React.FC<Props> = ({ locations, setLocations, departments }) => {

  const columns = [
    {   
      field: 'name',
      filter: true,
    },
    {   
      field: 'address',
      filter: true,
    },
    {   
      field: 'country',
      filter: true,
    },
    {   
      field: 'department',
      filter: true,
    },
    {   
      field: 'details',
      filter: true,
    },
  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addLocation = (newLocation: Location) => {
    setLocations([ ...locations, newLocation ])

    setModalIsOpen(false)
  }

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={locations} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new location" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewLocationModal onSave={addLocation} locations={locations} departments={departments} />
      </Modal>
    </>
  );
};

export default Locations;