import React from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.css';
import styled from 'styled-components';
import { colors } from './constants';

const App: React.FC = () => {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
`;

export const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

export const LinkButton = styled.a`
  color: ${colors.darkGreen};
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  padding: 10px;
  border: 1px solid ${colors.darkGreen};
  border-radius: 4px;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkGreen};
    color: #ffffff;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 0px solid #ccc;
`;

export const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  color: ${({ active }) => (active ? colors.darkGreen : '#555')};
  text-wrap: nowrap;

  &:hover {
      color: ${colors.mediumGreen};
  }

  &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: ${({ active }) => (active ? '100%' : '0')};
      height: 3px;
      background-color: ${colors.darkGreen};
      transition: width 0.3s ease;
  }

  &:focus {
      outline: none;
      color: ${colors.darkGreen};
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 10px;

  }
`;
