import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title, Tabs, Tab } from '../../../App';
import { TABS } from '../../../constants';
import { useSupplier } from '../../../context/SupplierContext';
import Details from '../Components/Details';
import Button from '../../../components/Button';
import { Contract } from '../../../interfaces';

const ContractDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const { contractId } = state;
    const { contracts, setContracts } = useSupplier();

    const contractIndex = contracts.findIndex((contract) => contract.id === contractId) || 0
    const contract = contracts[contractIndex]

    const [newContract, setNewContract] = useState(contract);

    const updateContract = (newContract: Contract) => {
        setNewContract(newContract)
    }

    const [activeTab, setActiveTab] = useState('details');
    const [isEdit, setIsEdit] = useState(false);

    const save = () => {
        setIsEdit(false)
        contracts[contractIndex] = newContract
        setContracts([...contracts])
    }

    const tabConfig = [
        {
          label: "Contract details",
          key: "details",
          component:
            <>
                <Details
                    contract={newContract}
                    setContract={updateContract}
                    isEditModeOn={isEdit}
                />
                { isEdit
                    ? <Button onClick={save}>Save</Button>
                    : <Button onClick={() => setIsEdit(true)}>Edit contract</Button>
                }
            </>
        },

      ];

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Contract { contract?.id }</Title>

                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }

                </Content>
            </Container>
        </>
    );
};

export default ContractDetails;