import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { Department, User, UserRole } from '../../interfaces';
import { ModalInputWrapper as InputWrapper, ModalButtonContainer as ButtonContainer } from '../..';
import { languages } from '../../../../mocks';

interface AddNewItemModal {
    onSave: (user: User) => void;
    userRoles?: UserRole[];
    departments?: Department[];
}

const AddNewUserModal: React.FC<AddNewItemModal> = ({ onSave, userRoles, departments }) => {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [position, setPosition] = useState('');
    const [role, setRole] = useState(userRoles?.[0]?.name || '');
    const [department, setDepartment] = useState(departments?.[0]?.name || '');
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState(languages?.[0].value || '');

    const [error, setError] = useState('')

    const onEmailInputChange = (value: string) => {
        setError('')
        setEmail(value)
    }

    const save = () => {
        setError('')

        if (!email || !email.includes('@')) {
            setError('Please insert valid email')
            return
        }

        const user = {
            email,
            firstname,
            lastname,
            position,
            role,
            department,
            language
        }

        onSave(user)
    }

    const userRoleOptions = userRoles?.map(role => {
        return {
            value: role.name,
            label: role.name,
        }
    })

    const departmentsOptions = departments?.map(dep => {
        return {
            value: dep.name,
            label: dep.name,
        }
    })

  return (
    <>      
      <Title>Create new user</Title>
      <div>
      <InputWrapper >
            <label htmlFor='email'>Email</label>
            <Input name='email' value={email} onChange={onEmailInputChange} error={error} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='firstname'>First name</label>
            <Input name='firstname' value={firstname} onChange={(value) => setFirstname(value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='lastname'>Surname</label>
            <Input name='lastname' value={lastname} onChange={(value) => setLastname(value)} />
        </InputWrapper>

        <InputWrapper >
            <label htmlFor='userRoles'>User role</label>
            <Select options={userRoleOptions || []} name='role' required value={role || ''} onChange={(e) => setRole(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='position'>Position</label>
            <Input name='position' value={position} onChange={(value) => setPosition(value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='departments'>Departments</label>
            <Select options={departmentsOptions || []} name='department' value={department} required onChange={(e) => setDepartment(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='language'>Language</label>
            <Select options={languages} name='language' value={language} required onChange={(e) => setLanguage(e.target.value)} />
        </InputWrapper>
       
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewUserModal;