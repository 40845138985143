import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import { RoundButtonContainer } from '..';
import AddNewDepartmentModal from './Modal';
import { Content } from '../../../App';
import { Department } from '../interfaces';

interface Props {
  departments: Department[],
  setDepartments: (departments: Department[]) => void
}

const Departments: React.FC<Props>  = ({ departments, setDepartments }) => {

  const columns = [
    {   
        field: 'name',
        filter: true,
    },
    {   
      field: 'details',
      filter: true,
    },
  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addDepartment = (department: Department) => {
    setDepartments([ ...departments, department ])
    setModalIsOpen(false)
  }

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={departments} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new department" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewDepartmentModal onSave={addDepartment} departments={departments} />
      </Modal>
    </>
  );
};

export default Departments;