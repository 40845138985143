import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, URLS } from '../../constants';
import Input from '../../components/Input';
import PasswordInput from '../../components/Password';
import Button from '../../components/Button';
import { User, useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Select';
import Error from '../../components/Error';
import InfoBox from '../../components/InfoBox';
import Checkbox from '../../components/Checkbox';

const SignUpForm: React.FC = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    role: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [error, setError] = useState('');

  const { setUser } = useUser();
  const navigate = useNavigate();
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!termsAndConditions) { 
        setError('Please accept the terms and conditions');
        return;
    }

    if (!formData.companyName || !formData.role || !formData.firstname || !formData.lastname || !formData.email || !formData.password) {
      setError('Please fill in all required fields');
      return;
    }

    const user = {
      companyName: formData.companyName,
      companyType: formData.role,
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      password: formData.password,

    } as User;

    setUser(user);
    console.log(user);

    if (user?.companyType === 'company' && user?.profileCompleted) {
      return navigate('/dashboard');
    }

    if (user?.companyType === 'company' && !user?.profileCompleted) {
      return navigate('/signup/company');
    }

    navigate('/dashboard');
  };

return (
    <Wrapper>
        <div>
            <FormContainer>
                <InfoBox>
                    Join us in building a sustainable future with a transparent supply chain.
                    Let&apos;s create your business profile!
                </InfoBox>
                <FormTitle>Create Profile</FormTitle>
                <StyledForm onSubmit={handleSubmit}>

                  <Select 
                    label='Who Are You?'
                    name='role'
                    value={formData.role}
                    onChange={(e) => setFormData({...formData, role: e.target.value})}
                    required
                    options={[{ value: 'company', label: 'Company' }, { value: 'supplier', label: 'Supplier' }]}
                  />

                    <Input
                        label="Company Name"
                        name="companyName"
                        value={formData.companyName}
                        onChange={(value) => setFormData({...formData, companyName: value})}
                        required
                    />

                    <Input
                        label="First Name"
                        name="firstname"
                        value={formData.firstname}
                        onChange={(value) => setFormData({...formData, firstname: value})}
                        required
                    />

                    <Input
                        label="Last Name"
                        name="lastname"
                        value={formData.lastname}
                        onChange={(value) => setFormData({...formData, lastname: value})}
                        required
                    />      

                    <Input
                        label="Business email"
                        name="email"
                        value={formData.email}
                        onChange={(value) => setFormData({...formData, email: value})}
                        required
                    />  

                    <PasswordInput
                        value={formData.password}
                        label="Password"
                        name="password"
                        onChange={(value) => setFormData({...formData, password: value})}   
                        required
                    />

                    <PasswordInput
                        value={formData.confirmPassword}
                        label="Confirm Password"
                        name="confirmPassword"
                        onChange={(value) => setFormData({...formData, confirmPassword: value})}   
                        required
                    />
                    
                    <Checkbox 
                      label={<span>I accept the <StyledLink undelined href={URLS.terms}>terms and conditions</StyledLink></span>}
                      name='termsAndConditions'
                      checked={termsAndConditions}
                      onChange={(checked) =>
                        setTermsAndConditions(checked)
                      }
                    />

                    {error && <Error>{error}</Error>}

                    <Button type="submit" onClick={handleSubmit}>Create Profile</Button>
                </StyledForm>
            </FormContainer>
            <LoginLink>
              Already have an account? <StyledLink href="/login">Login here</StyledLink>
            </LoginLink>
        </div>
    </Wrapper>
);
};

export default SignUpForm;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 30px 0;
    
`;

const FormContainer = styled.div`
  width: 400px;
  margin: 0 auto;
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media  screen and (max-width: 600px) {
    width: 95%;
  }
`;

const FormTitle = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const StyledLink = styled.a<{ undelined?: boolean }>`
  color: ${colors.darkGreen};
  text-decoration: ${({ undelined }) => (undelined ? 'underline' : 'none')};
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
