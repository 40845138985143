import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewItemModal from './Modal';
import { RoundButtonContainer } from '..';
import { Content } from '../../../App';
import { Category, Item } from '../interfaces';

interface Props {
  categories: Category[];
  setItems: (items: Item[]) => void;
  items: Item[];
  updateCategoryTotal: (cat: string) => void;
}

const Items: React.FC<Props> = ({ categories, setItems, items, updateCategoryTotal }) => {

  const columns = [
    {   
      field: 'name',
      filter: true,
    },
    {   
      field: 'category',
      filter: true,
    },
    {   
      field: 'uom',
      filter: true,
    },
    {   
      field: 'status',
      filter: true,
    },
    {   
      field: 'createdBy',
      filter: true,
    },
    {   
      field: 'createdAt',
      filter: true,
    },
    {   
      field: 'lastUpdated',
      filter: true,
    },
    {   
      field: 'details',
      filter: true,
    },
  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addItem = (newItem: Item) => {
    setItems([ ...items, newItem ])

    updateCategoryTotal(newItem.category)
    setModalIsOpen(false)
  }

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={items} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new item" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewItemModal onSave={addItem} categories={categories} items={items} />
      </Modal>
    </>
  );
};

export default Items;