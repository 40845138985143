export const checkboxConfig = [
    { 
        name: 'dashboard',
        label: 'Dashboard',
        checkboxes: { read: true, create: false, edit: false, export: true },
    },
    {
        name: 'suppliers',
        label: 'Suppliers',
        checkboxes: { read: true, create: true, edit: true, export: true },
    },
    {
        name: 'contracts',
        label: 'Contract',
        checkboxes: { read: true, create: true, edit: true, export: true },
    },
    {
        name: 'inAppMessages',
        label: 'In-app Messages',
        checkboxes: { read: true, create: true, edit: true, export: true },
    },
    {
        name: 'companySettings',
        label: 'Company Settings',
        checkboxes: { read: true, create: false, edit: true, export: false },
      },
      {
        name: 'productCategories',
        label: 'Product Categories',
        checkboxes: { read: true, create: true, edit: true, archive: true },
      },
      {
        name: 'items',
        label: 'Items',
        checkboxes: { read: true, create: true, edit: true, archive: true },
      },
      {
        name: 'departments',
        label: 'Departments',
        checkboxes: { read: true, create: true, edit: true, archive: true },
      },
      {
        name: 'locations',
        label: 'Locations',
        checkboxes: { read: true, create: true, edit: true, archive: true },
      },
      {
        name: 'users',
        label: 'Users',
        checkboxes: { read: true, create: true, edit: true, deactivate: true },
      },
      {
        name: 'roles',
        label: 'Roles',
        checkboxes: { read: true, create: true, edit: true, export: false },
      },
]