import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useUser } from '../../../../context/UserContext';
import { UserRole, Permission } from '../../interfaces';
import { ModalButtonContainer as ButtonContainer } from '../..';
import Checkbox from '../../../../components/Checkbox';
import styled from 'styled-components';
import { checkboxConfig } from './config';
import { buildDefaultPermissions } from './utils';

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 10px;

    > label {
        min-width: 150px;
        padding-top: 10px;
    }

    > div {
        margin-bottom: 0;
        flex-basis: 100%;
    }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(4, 1fr);
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.div`
  margin-bottom: 10px;
`;

const SubHeading = styled.div`
    font-size: 15px;
    margin-bottom: 20px;
`

const EmptySpace = styled.div`
  width: 90px;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`

interface AddNewUserRoleModalProps {
    onSave: (userRole: UserRole) => void;
    userRoles: UserRole[];
}

const AddNewUserRoleModal: React.FC<AddNewUserRoleModalProps> = ({ onSave, userRoles }) => {
    const [userRoleName, setUserRoleName] = useState('');
    const [description, setDescription] = useState('');
    const { user } = useUser();

    const [permissions, setPermissions] = useState({
        dashboard: { ...buildDefaultPermissions('dashboard') },
        suppliers: { ...buildDefaultPermissions('suppliers') },
        requests: { ...buildDefaultPermissions('requests') },
        contracts: { ...buildDefaultPermissions('contracts') },
        inAppMessages: { ...buildDefaultPermissions('inAppMessages') },
        companySettings: { ...buildDefaultPermissions('companySettings') },
        productCategories: { ...buildDefaultPermissions('productCategories') },
        items: { ...buildDefaultPermissions('items') },
        departments: { ...buildDefaultPermissions('departments') },
        locations: { ...buildDefaultPermissions('locations') },
        users: { ...buildDefaultPermissions('users') },
        roles: { ...buildDefaultPermissions('roles') },
      });

    const handleCheckboxChange = (module: keyof typeof permissions, permission: keyof Permission) => {
        setPermissions((prev) => ({
          ...prev,
          [module]: {
            ...prev[module],
            [permission]: !prev[module][permission],
          },
        }));
      };

    const [error, setError] = useState('');

    const save = () => {
        setError('')

        if (!userRoleName) {
            setError('Please insert user role name')
            return
        }

        if (userRoles.find(({ name }) => name === userRoleName)) {
            setError('Please insert unique user role name')
            return
        }

        const userRole = {
            name: userRoleName,
            details: description,
            createdAt: new Date().toDateString(),
            createdBy: user?.firstname + ' ' + user?.lastname,
            totalUsers: 0,
            permissions
        } as UserRole

        onSave(userRole)
        console.log({ userRole })
    }

    const onRoleInputChange = (value: string) => {
        setError('')
        setUserRoleName(value)
    }

    const permissionsList = ["read", "create", "edit", "export", "archive", "deactivate"];

    const handleSelectAll = () => {
        setPermissions((prev) => {
          const updatedPermissions = { ...prev };
          Object.keys(updatedPermissions).forEach((module) => {
            Object.keys(updatedPermissions[module as keyof typeof permissions]).forEach((perm) => {
              updatedPermissions[module as keyof typeof permissions][perm as keyof Permission] = !areAllPermissionsSelected;
            });
          });
          return updatedPermissions;
        });
    };

    const areAllPermissionsSelected = 
         Object.values(permissions).every((module) =>
            Object.values(module).every((perm) => perm === true)
        );

  return (
    <>      
      <Title>Create new category</Title>
      <div>
        <InputWrapper >
            <label htmlFor='name'>Category name</label>
            <Input name='name' value={userRoleName} onChange={onRoleInputChange} error={error} />
        </InputWrapper>

        <InputWrapper >
            <label htmlFor='description'>Description</label>
            <Input name='desc' textarea value={description} onChange={(value) => setDescription(value)} />
        </InputWrapper>

        <Flex>
            <SubHeading>Permissions</SubHeading>
            <Checkbox
                checked={areAllPermissionsSelected}
                name="selectAll"
                label="Select all"
                onChange={handleSelectAll}
                labelStyle={{ fontWeight: 400 }}
                style={{ margin: 0 }}
            />
        </Flex>

        {checkboxConfig.map((module, index) => (
         <GridContainer key={index}>
         <Label>{module.label}</Label>
         {permissionsList.map((permission, permIndex) => 
           {
            if (module.checkboxes[permission as keyof typeof module.checkboxes] === undefined) return null
            return module.checkboxes[permission as keyof typeof module.checkboxes] ? (
             <>
               <Checkbox
                  key={permission}
                  label={permission}
                  checked={permissions[module.name as keyof typeof permissions][permission as keyof Permission]}
                  name={`${module.name}-${permission}`}
                  style={{ margin: 0 }}
                  labelStyle={{ fontWeight: 400 }}
                  onChange={() => handleCheckboxChange(module.name as keyof typeof permissions, permission as keyof Permission)}
                />
             </>
           ) : (
             <EmptySpace key={permIndex} />
           )}
         )}
       </GridContainer>
      ))}
        
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewUserRoleModal;