import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../../components/Sidebar';
import { colors, TABS, URLS } from '../../../constants';
import Header from '../../../components/Header';
import { Supplier } from '../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from '../../../App';
import RoundButton from '../../../components/RoundButton';
import { useSupplier } from '../../../context/SupplierContext';
import { countries_ } from '../../../mocks';
import Select from '../../../components/Select';
import { IoLocationOutline } from "react-icons/io5";
import { LuPackage } from "react-icons/lu";

const ITEMS_PER_PAGE = 30;

const FindSupplier: React.FC = () => {
  const [countryFilter, setCountryFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { suppliers } = useSupplier();
  const country = countries_.find(c => c.value === countryFilter)?.label;

  const filteredSuppliers = suppliers?.filter(supplier => {
    const matchesCountry = countryFilter === '' || supplier.country === country;
    return matchesCountry;
  });

  const totalPages = Math.ceil((filteredSuppliers?.length || 0) / ITEMS_PER_PAGE);
  const paginatedSuppliers = filteredSuppliers?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleClickOnCard = (supplier: Supplier) => {
    navigate(URLS.supplierDetails, { state: { supplierId: supplier.id } });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Header />
      <Container>
        <Sidebar tab={TABS.suppliers} />
        
        <Content>
          <FiltersHeader>
            <FiltersContainer>
              <Select
                options={countries_}
                name="country"
                value={countryFilter}
                onChange={e => {
                  setCountryFilter(e.target.value);
                  setCurrentPage(1); 
                }}
                placeholder={'Location'}
              />
            </FiltersContainer>

            <Divisor />

              <FilterSummary>
                Showing <span>{filteredSuppliers.length}</span> suppliers <br></br>
                { countryFilter && <> in <span>{country}</span></> }
              </FilterSummary>
          </FiltersHeader>

          <CardContainer>
            {paginatedSuppliers?.map(supplier => (
              <SupplierCard key={supplier.id} onClick={() => handleClickOnCard(supplier)}>
                <CardTitle>{supplier.company}</CardTitle>
                <CardContent>
                  <CardInfo><IoLocationOutline color={colors.darkOrange} /> {supplier.country}</CardInfo>
                  <CardInfo><LuPackage color={colors.darkOrange} /> {supplier.product_categories}</CardInfo>
                </CardContent>
                <CardButton>Contact</CardButton>
              </SupplierCard>
            ))}
          </CardContainer>

          <Pagination>
            <PaginationButton
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </PaginationButton>
            <PageInfo>
              Page {currentPage} of {totalPages}
            </PageInfo>
            <PaginationButton
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </PaginationButton>
          </Pagination>

          <RoundButtonWrapper>
            <RoundButton onClick={() => navigate(URLS.addSupplier)} text='Add new supplier' size={60}/>
          </RoundButtonWrapper>
        </Content>
      </Container>
    </>
  );
};

const FilterSummary = styled.div`
  font-size: 14px;
  width: 170px;
  font-weight: 600;
  color: ${colors.darkGreen};
  span {
    color: ${colors.mediumGreen};
  }
`;

const FiltersHeader = styled.div`
  display: flex;
  justify-content: end;
`;

const Divisor = styled.div`
  width: 2px;
  height: 35px;
  margin: 0 20px;
  background-color: ${colors.darkOrange};
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
`;

const SupplierCard = styled.div`
  background-color: ${colors.lightGrey};
  border-radius: 8px;
  width: 340px;
  flex-grow: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  font-size: 18px;
  padding: 10px 20px;
  margin-right: 50px;
  color: white;
  margin-bottom: 10px;
  background-color: ${colors.darkGreen};
  border-bottom-right-radius: 20px;
`;

const CardInfo = styled.p`
  margin: 5px 0;
`;

const CardContent = styled.div`
  font-size: 14px;
  padding: 10px 20px 20px 20px;
  color: ${colors.darkGreen};
`;

const CardButton = styled.div`
  color: ${colors.darkGreen};
  font-size: 14px;
  margin: 15px;
  padding: 5px 10px;
  border-radius: 15px;
  width: fit-content;
  margin-top: auto;
  border: 1px solid ${colors.darkGreen};
  align-self: flex-end;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  border: 1px solid ${colors.darkGreen};
  background-color: ${colors.lightGrey};
  color: ${colors.darkGreen};
  cursor: pointer;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.darkGreen};
    color: white;
  }
`;

const PageInfo = styled.div`
  font-size: 14px;
  color: ${colors.darkGreen};
`;

const RoundButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  position: fixed;
  bottom: 40px;
  right: 0px;
  background-color: #ffffff93;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  
  &:hover {
    background-color: #fff;
  }
`;

export default FindSupplier;
