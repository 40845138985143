import React from 'react';
import styled from 'styled-components';
import { useApp } from '../../context/AppContext';
import { FaHome, FaSearch, FaFileContract, FaBars, FaChevronLeft, FaUser } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { colors } from '../../constants';
import { TABS } from '../../constants';

const Wrapper = styled.div<{ $isCollapsed: boolean }>`
  min-width: ${({ $isCollapsed }) => ($isCollapsed ? '60px' : '200px')};

  padding-left: ${({ $isCollapsed }) => ($isCollapsed ? '10px' : '20px')};
  padding-top: 40px;
  transition: min-width 0.3s ease, padding-left 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
 position: sticky;
 top: 30px;
`; 

const Tab = styled.div<{ $isActive: boolean }>`
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({ $isActive }) => ($isActive ? '#ffffff' : 'transparent')};
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};
  color: ${({ $isActive }) => ($isActive ? colors.darkGreen : '#333')};
  border-radius: 4px;

  &:hover {
    color: ${colors.darkGreen};
  }

  svg {
    margin-bottom: 3px;
  }
`;

const Label = styled.div<{ $isCollapsed: boolean }>`
  display: ${({ $isCollapsed }) => ($isCollapsed ? 'none' : 'block')};
  transition: display 0.3s ease;
`;

const ToggleButton = styled.div<{ $isCollapsed: boolean }>`
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  justify-content: flex-start;
`;

interface SidebarProps {
  tab?: string;
}

const sidebarConfig = [
  {
    id: TABS.dashboard,
    label: 'Dashboard',
    icon: <FaHome />,
  },
  {
    id: TABS.suppliers,
    label: 'Find Supplier',
    icon: <FaSearch />,
  },
  {
    id: TABS.contracts,
    label: 'Contracts',
    icon: <FaFileContract />,
  },
  {
    id: TABS.profile,
    label: 'My account',
    icon: <FaUser />,
  },
  {
    id: TABS.settings,
    label: 'Admin Panel',
    icon: <FaGear />,
  },
];

const Sidebar: React.FC<SidebarProps> = ({ tab }) => {
  const { activeTab: tabClicked, handleTabClick } = useApp();
  const { isSidebarOpen, toggleSideBar } = useApp();

  const activeTab = tabClicked || tab;
  const isCollapsed = !isSidebarOpen;

  return (
    <Wrapper $isCollapsed={isCollapsed}>
      <Content>
        <ToggleButton $isCollapsed={isCollapsed} onClick={toggleSideBar}>
          {!isCollapsed ? <FaChevronLeft /> : <FaBars />}
        </ToggleButton>

        {sidebarConfig.map(({ id, label, icon }) => (
          <Tab
            key={id}
            $isActive={activeTab === id}
            onClick={() => handleTabClick(id)}
          >
            {icon}
            <Label $isCollapsed={isCollapsed}>{label}</Label>
          </Tab>
        ))}
      </Content>
    </Wrapper>
  );
};

export default Sidebar;
