import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../context/UserContext';
import { colors } from '../../constants';
import { FaSignOutAlt } from 'react-icons/fa';
import { useApp } from '../../context/AppContext';

const Header: React.FC = () => {
    const { user, logout } = useUser();
    const { activeTab } = useApp();

    return (
        <HeaderWrapper>
            <LogoHeader>
                <Logo>
                    <img src="/assets/logo_w.png" alt="Ecoconnect Logo" />
                </Logo>
                {user && (
                    <UserName>
                        <Logout onClick={logout}>
                            <FaSignOutAlt color={'white'} />
                        </Logout>
                    </UserName>
                )}
            </LogoHeader>

            { user?.profileApproved && <BreadcrumbContainer>
                <ClientProfile>
                    <ProfilePic src={'/assets/profile_default.png'} alt="User profile" />
                    <Arrow>{'>'} </Arrow>
                </ClientProfile>
                <Flex>
                    <BreadcrumbText>
                        {user?.companyName}
                        <ArrowSmall>{'>'} </ArrowSmall>
                        <Tab style={{ textTransform: 'capitalize' }}>{activeTab }</Tab>
                    </BreadcrumbText>
                    <AccountSettings>
                       <div>Profile: <span>verified</span></div>
                       <Divisor />
                       <div>Account status: <span>active</span></div>
                    </AccountSettings>
                </Flex>
            </BreadcrumbContainer> }
        </HeaderWrapper>
    );
};

export default Header;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
`;

const AccountSettings = styled.div`
    font-weight: 600;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: ${colors.darkGreen};
    align-items: center;
    span {
        color: ${colors.mediumGreen};
    }
`;
const HeaderWrapper = styled.div`
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Tab = styled.span`
    color: ${colors.darkOrange};
    font-size: 18px;
`;

const LogoHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
    background-color: ${colors.darkGreen};
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    width: 50px;

    img {
        width: 100%;
    }
`;

const Divisor = styled.div`
    width: 2px;
    height: 15px;
    background-color: ${colors.darkOrange};
`

const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 20px;
    width: 100%;
`;

const ClientProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
`;

const ProfilePic = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${colors.lightGrey};;
`;

const Arrow = styled.span`
    font-size: 20px;
    color: ${colors.darkOrange};
`;

const ArrowSmall = styled.span`
    font-size: 16px;
    color: ${colors.darkOrange};
`;

const BreadcrumbText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${colors.darkGreen};
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;

`;

const UserName = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.darkGreen};
`;

const Logout = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;
