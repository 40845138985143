import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';
import { FaUpload } from 'react-icons/fa';

interface FileUploadProps {
    type: 'picture' | 'document';
    disabled?: boolean;
};

const FileUpload: React.FC<FileUploadProps> =  ({ type, disabled }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const accept = type === 'picture' ? 'image/*' : 'application/pdf';

  const handleClick = () => {
    document.getElementById("file-input")?.click();
  }

  return (
    <Wrapper onClick={handleClick}>
      <input type="file" accept={accept} onChange={uploadImage} id="file-input" disabled={disabled} />
      {imageUrl && <img src={imageUrl} alt="uploaded file" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />}
      { !imageUrl && <UploadIcon>
        <FaUpload size={24} />
      </UploadIcon> }
    </Wrapper>
  );
};

const Wrapper = styled.div` 
  width: 100px;
  height: 100px;
  background-color: ${colors.lightGreen};
  border-radius: 50%;
  position: relative;

  input {
     display: none;
  }
`;

const UploadIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #07702f;
  pointer-events: none; 
`;

export default FileUpload;
