import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewCategoryModal from './Modal';
import { UserRole } from '../interfaces';
import { RoundButtonContainer } from '..';
import { Content } from '../../../App';

interface Props {
  userRoles: UserRole[],
  setUserRoles: (role: UserRole[]) => void
}

const UserRoles: React.FC<Props>  = ({ userRoles, setUserRoles }) => {

  const columns = [
    {   
        field: 'name',
        filter: true,
    },
    {   
      field: 'totalUsers',
      filter: true,
    },
    {   
      field: 'createdBy',
      filter: true,
    },
    {   
      field: 'createdAt',
      filter: true,
    },
    {   
      field: 'details',
      filter: true,
    },

  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addUserRole = (role: UserRole) => {
    setUserRoles([ ...userRoles, role ])
    setModalIsOpen(false)
  }

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={userRoles} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new role" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewCategoryModal onSave={addUserRole} userRoles={userRoles} />
      </Modal>
    </>
  );
};

export default UserRoles;