import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewUserModal from './Modal';
import { RoundButtonContainer } from '..';
import { Content } from '../../../App';
import { Department, User, UserRole } from '../interfaces';

interface Props {
  userRoles: UserRole[];
  setUsers: (users: User[]) => void;
  users: User[];
  updateUserRolesTotal: (userRole: string) => void;
  departments: Department[];
}

const Users: React.FC<Props> = ({ userRoles, setUsers, users, departments, updateUserRolesTotal }) => {

  const columns = [
    {   
      field: 'name',
      filter: true,
    },
    {   
      field: 'email',
      filter: true,
    },
    {   
      field: 'role',
      filter: true,
    },
    {   
      field: 'position',
      filter: true,
    },
    {   
      field: 'department',
      filter: true,
    },
    {   
      field: 'language',
      filter: true,
    },
  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addUser = (user: User) => {
    setUsers([ ...users, user ])

    updateUserRolesTotal(user.role)
    setModalIsOpen(false)
  }

  const usersMod = users.map((user) => {
    return {
      ...user,
      name: user.firstname + ' ' + user.lastname
    }
  })

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={usersMod} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new item" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewUserModal onSave={addUser} userRoles={userRoles} departments={departments} />
      </Modal>
    </>
  );
};

export default Users;