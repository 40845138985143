export const itemsColumns = [
    {
        field: 'id',
        filter: true,
    },
    {
        field: 'productCategory',
        filter: true,
    },
    {
        field: 'material',
        filter: true,
    },
    {
        field: 'ethicalStandard',
        filter: true,
    },
    {
        field: 'capacityRange',
        filter: true,
    },
    {
        field: 'certifications',
        filter: true,
    },
    {
        field: 'quantity',
        filter: true,
    },
    {
        field: 'unitPrice',
        filter: true,
    },
    {
        field: 'details',
        filter: true,
    },
    {
        field: 'subtotal',
        filter: true,
    },
]