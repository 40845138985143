import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { useUser } from '../../../../context/UserContext';
import { Category, Item } from '../../interfaces';
import { ModalInputWrapper as InputWrapper, ModalButtonContainer as ButtonContainer } from '../..';

interface AddNewItemModal {
    onSave: (item: Item) => void;
    categories?: Category[];
    items?: Item[];
}

const AddNewItemModal: React.FC<AddNewItemModal> = ({ onSave, categories, items }) => {

    const statusOptions = [
        { label: 'status1', value: 'status1' },
        { label: 'status2', value: 'status2' }
    ]

    const unitOptions = [
        { value: 'kg', label: 'kg' },
        { value: 'piece', label: 'piece' },
        { value: 'cm', label: 'cm' },
        { value: 'g', label: 'g' },
        { value: 'm', label: 'm' },
        { value: 'liter', label: 'liter' },
        { value: 'ml', label: 'ml' },
        { value: 'oz', label: 'oz' },
        { value: 'lb', label: 'lb' },
        { value: 'other', label: 'other' },
      ];

    const [itemName, setItemName] = useState('');
    const [itemCategory, setItemCategory] = useState(categories?.[0]?.name);
    const [uom, setUom] = useState(unitOptions[0].value);
    const [status, setStatus] = useState(statusOptions[0].value);
    const [description, setDescription] = useState('');
    const { user } = useUser();

    const [error, setError] = useState('')


    const onItemInputChange = (value: string) => {
        setError('')
        setItemName(value)
    }

    const save = () => {
        setError('')

        if (!itemName) {
            setError('Please insert item name')
            return
        }

        if (items?.find(({ name }) => name === itemName)) {
            setError('Please insert unique category name')
            return
        }

        if (!itemCategory) return 

        const item = {
            name: itemName,
            category: itemCategory,
            uom,
            status,
            details: description,
            createdAt: new Date().toDateString(),
            createdBy: user?.firstname + ' ' + user?.lastname,
            totalItems: 0,
            lastUpdated: new Date().toDateString()
        }

        onSave(item)
    }

    const categoriesOptions = categories?.map(cat => {
        return {
            label: cat.name,
            value: cat.name
        }
    })

  return (
    <>      
      <Title>Create new item</Title>
      <div>
        <InputWrapper >
            <label htmlFor='name'>Item name</label>
            <Input name='name' value={itemName} onChange={onItemInputChange} error={error} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='category'>Item category</label>
            <Select options={categoriesOptions || []} name='name' required value={itemCategory || ''} onChange={(e) => setItemCategory(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='statuses'>Status</label>
            <Select options={statusOptions} name='name' value={status} required onChange={(e) => setStatus(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='uom'>UoM</label>
            <Select options={unitOptions} name='name' value={uom} required onChange={(e) => setUom(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='description'>Description</label>
            <Input name='desc' textarea value={description} onChange={(value) => setDescription(value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='images'>Item images</label>
            <Input name='images' type="file" />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='specififcation'>Item specififcation</label>
            <Input name='specififcation' type="file" />
        </InputWrapper>
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewItemModal;