import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title } from '../../../App';
import styled from 'styled-components';
import { colors, TABS } from '../../../constants';
import Checkbox from '../../../components/Checkbox';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewDocumentModal from '../AddNewDocumentModal';
import { SupplierDocument } from '../../../interfaces';
import { documentTypes } from '../../../mocks';
import { useSupplier } from '../../../context/SupplierContext';
import StarRating from '../../../components/Rating';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const SupplierDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const [documentsModalIsOpened, setDocumentsModalIsOpened] = useState(false);

    const { supplierId } = state;
    const { suppliers, setSuppliers } = useSupplier();

    const supplier = suppliers.find((supplier) => supplier.id === supplierId);
    const { documents, company, id, brandnames, country, countryregion, product_spec, product_categories } = supplier || {};

    const documentsColumns = [
        {
            field: 'name',
            filter: true,
        },
        {
            field: 'type',
            filter: true,
        },
        {
            field: 'expiry',
            filter: true,
        }
    ]

    const complianceColumns = [
        {   
            field: 'performance',
            filter: true,
        },
        {
            field: 'score',
            filter: true,
        },
        {
            field: 'sustainability',
            filter: true,
        },
        {
            field: 'score',
            filter: true,
        },
        {
            field: 'risk',
            filter: true,
        },
    ]

    const [preferredSupplier, setPreferredSupplier] = useState(false);
    // const [documentPreviewModalIsOpened, setDocumentPreviewModalIsOpened] = useState(false);
    // const [documentPreviewUri, setDocumentPreviewUri] = useState<string>('');

    
    const onSaveDocument = (document: SupplierDocument) => {
        const document_ = { ... document, type: documentTypes.find((type) => type.value === document.type)?.label || document.type }

        const supplierIndex = suppliers.findIndex((supplier) => supplier.id === supplierId);
        const suppliers_ = suppliers;
        suppliers_[supplierIndex].documents = [...(suppliers_[supplierIndex].documents || []), document_];

        setSuppliers(suppliers_);
        setDocumentsModalIsOpened(false);
    }

    // const showDocumentPreivew = (attachment: File) => {
    //     setDocumentPreviewModalIsOpened(true);
    //     console.log(attachment);

    //     setDocumentPreviewUri('/assets/general_dov.docx');
    // }

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.suppliers} />
                
                <Content>
                    <Title>{ company }</Title>
                    <BlocksWrapper>
                        <Block>
                            <div>
                                <Subtitle>General info</Subtitle>
                                <TableBox>
                                    <div><Name>Supplier ID:</Name> {id}</div>
                                    <div><Name>Supplier Brandnames:</Name> {brandnames || '-'}</div>
                                    <div><Name>Supplier Product specs:</Name> {product_spec}</div>
                                    <div><Name>Supplier Product categories:</Name> {product_categories}</div>
                                    <div><Name>Supplier Country:</Name> {country}</div>
                                    <div><Name>Supplier Country region:</Name> {countryregion?.trim() || '-'}</div>

                                    <Stars>
                                        <StarRating />
                                    </Stars>

                                    <div>
                                        <Checkbox 
                                            style={{ marginBottom: 0 }}
                                            labelStyle={{ color: 'black', fontWeight: 300 }}
                                            label='Mark as preferred'
                                            name='preferredSupplier'
                                            checked={preferredSupplier}
                                            onChange={(checked) =>
                                                setPreferredSupplier(checked)
                                            }
                                        />
                                    </div>
                                </TableBox>
                            </div>
                        </Block>

                        <Block>
                            <div>
                                <Subtitle>Supplier documents</Subtitle>
                                <Table
                                    height={300}
                                    rowData={documents || []}
                                    columns={documentsColumns}
                                    // onRowClick={row => showDocumentPreivew(row.data.attachment)}
                                />
                                <RoundButtonContainer>
                                    <RoundButton text='Add document' onClick={() => setDocumentsModalIsOpened(true)} size={35}/>
                                </RoundButtonContainer>
                            </div>

                            <div>
                                <Subtitle>Supplier compliance</Subtitle>
                                <Table
                                    height={300}
                                    rowData={[{ performance: 'Performance', score: 5, sustainability: 'Sustainability', risk: 'Risk' }]}
                                    columns={complianceColumns}
                                />
                            </div>
                        </Block>
                    </BlocksWrapper>

                </Content>
            </Container>

            <Modal
                isOpen={documentsModalIsOpened}
                onClose={() => setDocumentsModalIsOpened(false)}
            >
                <AddNewDocumentModal onSave={(document) => onSaveDocument(document)}/>
            </Modal>

            {/* <Modal
                isOpen={documentPreviewModalIsOpened}
                onClose={() => setDocumentPreviewModalIsOpened(false)}
            >
                { documentPreviewUri &&
                   <DocViewer documents={[ { uri: documentPreviewUri } ]} pluginRenderers={DocViewerRenderers} />
                }
            </Modal> */}
        </>
    );
};

export default SupplierDetails;

const BlocksWrapper = styled.div`   
    display: grid;
    grid-template-columns: 40fr 60fr;
    gap: 20px;
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
`;

const TableBox = styled.div`
    border: 1px solid ${colors.tableBorder};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Name = styled.span`
    color: grey;
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Subtitle = styled.h3`
    padding: 10px 15px;
    margin: 0;
    font-weight: 500;
`;

const Stars = styled.div`
    display: flex;
    gap: 5px;
    margin: 10px 0;

    > svg {
        cursor: pointer;
    }
`;

const RoundButtonContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
`;