import { checkboxConfig } from "./config";

export const buildDefaultPermissions = (module: string) => {
    const defaultPermissions: Record<string, boolean> = {};

    const findModule = checkboxConfig.find(({ name }) => name === module)

    if (!findModule || !findModule?.checkboxes) return
  
      Object.keys(findModule?.checkboxes).forEach((key) => {
        const value = findModule?.checkboxes[key as keyof typeof findModule.checkboxes];
  
        if (value === true || value === false) {
          defaultPermissions[key] = false;
        }
      });

    return defaultPermissions;
  };