export const contracts = [
  {
      id: 43211,
      title: "Office Supplies Agreement",
      reference: "OSA-2024",
      type: "Procurement",
      supplierId: 46782,
      country: "United States",
      department: "Operations",
      startDate: "2024-01-01",
      endDate: "2025-01-01",
      noticePeriod: "30 days",
      currency: "USD",
      estimatedValue: "50000",
      items: []
  },
  {
      id: 21249,
      title: "IT Services Contract",
      reference: "ITSC-2024",
      type: "Service",
      supplierId: 32274,
      country: "India",
      department: "IT",
      startDate: "2024-03-15",
      endDate: "2025-03-15",
      noticePeriod: "60 days",
      currency: "INR",
      estimatedValue: "2000000",
      items: []
  },
  {
      id: 44443,
      title: "Cleaning Services Contract",
      reference: "CSC-2024",
      type: "Service",
      supplierId: 36479,
      country: "Canada",
      department: "Operations",
      startDate: "2024-02-01",
      endDate: "2024-12-31",
      noticePeriod: "45 days",
      currency: "CAD",
      estimatedValue: "30000",
      items: []
  },
  {
      id: 34344,
      title: "Marketing Consultancy",
      reference: "MC-2024",
      type: "Consultancy",
      supplierId: 46556,
      country: "United Kingdom",
      department: "Marketing",
      startDate: "2024-04-01",
      endDate: "2024-10-01",
      noticePeriod: "30 days",
      currency: "GBP",
      estimatedValue: "15000",
      items: []
  }
]
