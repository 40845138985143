import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { useUser } from '../../../../context/UserContext';
import { Category } from '../../interfaces';
import { ModalInputWrapper as InputWrapper, ModalButtonContainer as ButtonContainer } from '../..';
interface AddNewCategoryModalProps {
    onSave: (category: Category) => void;
    categories: Category[];
}

const AddNewCategoryModal: React.FC<AddNewCategoryModalProps> = ({ onSave, categories }) => {
    const [categoryName, setCategoryName] = useState('');
    const [status, setStatus] = useState('status1');
    const [description, setDescription] = useState('');
    const { user } = useUser();

    const [error, setError] = useState('');

    const save = () => {
        setError('')

        if (!categoryName) {
            setError('Please insert category name')
            return
        }

        if (categories.find(({ name }) => name === categoryName)) {
            setError('Please insert unique category name')
            return
        }

        const category = {
            name: categoryName,
            status,
            details: description,
            createdAt: new Date().toDateString(),
            createdBy: user?.firstname + ' ' + user?.lastname,
            totalItems: 0,
            lastUpdated: new Date().toDateString()
        }

        onSave(category)
    }

    const statusOptions = [
        { label: 'status1', value: 'status1' },
        { label: 'status2', value: 'status2' }
    ]

    const onCategoryInputChange = (value: string) => {
        setError('')
        setCategoryName(value)
    }

  return (
    <>      
      <Title>Create new category</Title>
      <div>
        <InputWrapper >
            <label htmlFor='name'>Category name</label>
            <Input name='name' value={categoryName} onChange={onCategoryInputChange} error={error} />
        </InputWrapper>

        <InputWrapper >
            <label htmlFor='statuses'>Status</label>
            <Select options={statusOptions} name='name' value={status} onChange={(e) => setStatus(e.target.value)} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='description'>Description</label>
            <Input name='desc' textarea value={description} onChange={(value) => setDescription(value)} />
        </InputWrapper>
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewCategoryModal;