import React, { useState } from 'react';
import { Container, Tab, Tabs } from '../../App';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { colors, TABS } from '../../constants';
import PersonalProfile from './Personal';
import SecuritySettings from './Security';
import LanguagesSettings from './Languages';
import NotificationsSettings from './Notifications';
import styled from 'styled-components';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 500px;
`;

export const Content = styled.div`
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
`;

export const Block = styled.div`
  padding-bottom: 30px;
`;

export const Title = styled.h3`
    color: ${colors.darkGreen};
    margin-bottom: 20px;
    margin-top: 0;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    max-width: 300px;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    > button {
        margin-bottom: 10px;
    }
`;

const Profile: React.FC = () => {
    const [tab, setTab] = useState('personal');

    const tabConfig = [
        {
          label: "My Profile",
          key: "personal",
        },
        {
          label: "Security",
          key: "security",
        },
        {
            label: "Languages",
            key: "languages",
        },
        {
            label: "Notifications",
            key: "notifications",
        },
      ];

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.profile} />

                <Content>
                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setTab(tabItem.key)}
                                active={tab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                            ))}
                    </Tabs>

                    { tab === 'personal' && <PersonalProfile /> }
                    { tab === 'security' && <SecuritySettings /> }
                    { tab === 'languages' && <LanguagesSettings /> }
                    { tab === 'notifications' && <NotificationsSettings /> }
                </Content>
            </Container>
        </>
    );
};

export default Profile;
