import React, { useState } from 'react';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import { useUser } from '../../../context/UserContext';
import { ButtonsContainer } from '../../AdminPanel';
import { StyledForm, Content } from '..';
import { currencies, languages } from '../../../mocks';

const LanguagesSettings: React.FC = () => {  
  const { user } = useUser();

  const { settings } = user || {};
  const [formData, setFormData] = useState({
    language: settings?.general.language || '',
    timeZone: settings?.general.timeZone || '',
    currency: settings?.general.currency || '',
    dateFormat: settings?.general.dateFormat || '',
    unit: settings?.general.unit || ''
  });

  const submitAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ formData })
  };

  const timeZones = [{ value: 'est', label: 'EST' }, { value: 'pst', label: 'PST' }, { value: 'cst', label: 'CST' }];
  const units = [{ value: 'metric', label: 'Metric' }, { value: 'imperial', label: 'Imperial' }];
  const dateFormats = [{ value: 'mm/dd/yyyy', label: 'MM/DD/YYYY' }, { value: 'dd/mm/yyyy', label: 'DD/MM/YYYY' }, { value: 'yyyy/mm/dd', label: 'YYYY/MM/DD' }];

  return (
    <>      
      <Content>
        <StyledForm onSubmit={submitAccount}>          
          <Select
            options={languages}
            label="Language preferences"
            name="language"
            value={formData.language}
            onChange={(e) => setFormData({...formData, language: e.target.value })}
          />

          <Select
            options={timeZones}
            label="Time zone"
            name="timeZone"
            value={formData.timeZone}
            onChange={(e) => setFormData({...formData, timeZone: e.target.value })}
          />

          <Select
            options={currencies}
            label="Currency preferences"
            name="currency"
            value={formData.currency}
            onChange={(e) => setFormData({...formData, currency: e.target.value })}
          />

          <Select
            options={dateFormats}
            label="Date/Time format"
            name="dateFormat"
            value={formData.dateFormat}
            onChange={(e) => setFormData({...formData, dateFormat: e.target.value })}
          />

          <Select
            options={units}
            label="Measurement units"
            name="unit"
            value={formData.unit}
            onChange={(e) => setFormData({...formData, unit: e.target.value })}
          />

          <ButtonsContainer>
            <Button onClick={submitAccount}>Save</Button>
          </ButtonsContainer>
        </StyledForm>

      </Content>
    </>
  );
};



export default LanguagesSettings;