import React, { useState } from 'react';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { useUser } from '../../../context/UserContext';
import { ButtonsContainer, Title } from '../../AdminPanel';
import { StyledForm, Content, Block } from '..';

const NotificationsSettings: React.FC = () => {  
  const { user } = useUser();
  const { settings } = user || {};
  
  const [formData, setFormData] = useState({
    newMessages: settings?.notifications.email.newMessages || 'disabled',
    rfq: settings?.notifications.email.rfq || 'disabled',
    invitations: settings?.notifications.email.invitations || 'disabled',
    taskReminders: 'disabled',
    supplierUpdates: 'disabled',
    orderStatusChanges: 'disabled',
    criticalUpdates: 'disabled',
    lowStockAlerts: 'disabled'
  });

  const submitAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ formData })
  };

  const options = [{ value: 'enabled', label: 'Enabled' }, { value: 'disabled', label: 'Disabled' }];

  return (
    <>      
      <Content>
        <StyledForm onSubmit={submitAccount}>   
          <Block>
            <Title>Email Notifications </Title>
            <Select
              label="New mesages"
              name="newMesages"
              options={options}
              value={formData.newMessages}
              onChange={(e) => setFormData({ ...formData, newMessages: e.target.value as 'enabled' | 'disabled' })}
              required
            />

          <Select
              label="RFQ Responses"
              name="rfq"
              options={options}
              value={formData.rfq}
              onChange={(e) => setFormData({ ...formData, rfq: e.target.value as 'enabled' | 'disabled'})}
              required
            />

          <Select
              label="Invitations Accepted"
              name="invitatios"
              options={options}
              value={formData.invitations}
              onChange={(e) => setFormData({ ...formData, invitations: e.target.value as 'enabled' | 'disabled'})}
              required
            />
          </Block>  

          <Block>
            <Title>Web Notifications </Title>
            <Select
              label="Task Reminders"
              name="taskReminders"
              options={options}
              value={formData.taskReminders}
              onChange={(e) => setFormData({ ...formData, taskReminders: e.target.value as 'enabled' | 'disabled' })}
              required
            />

          <Select
              label="Supplier Updates"
              name="supplierUpdates"
              options={options}
              value={formData.supplierUpdates}
              onChange={(e) => setFormData({ ...formData, supplierUpdates: e.target.value as 'enabled' | 'disabled'})}
              required
            />

          <Select
              label="Order Status Changes"
              name="orderStatusChanges"
              options={options}
              value={formData.orderStatusChanges}
              onChange={(e) => setFormData({ ...formData, orderStatusChanges: e.target.value as 'enabled' | 'disabled'})}
              required
            />
          </Block>  

          <Block>
            <Title>SMS Notifications</Title>
            <Select
              label="Critical Updates"
              name="criticalUpdates"
              options={options}
              value={formData.criticalUpdates}
              onChange={(e) => setFormData({ ...formData, criticalUpdates: e.target.value as 'enabled' | 'disabled' })}
              required
            />

          <Select
              label="Low Stock Alerts"
              name="lowStockAlerts"
              options={options}
              value={formData.lowStockAlerts}
              onChange={(e) => setFormData({ ...formData, lowStockAlerts: e.target.value as 'enabled' | 'disabled'})}
              required
            />
          </Block>  

          <ButtonsContainer>
            <Button onClick={submitAccount}>Save</Button>
          </ButtonsContainer>
        </StyledForm>
      </Content>
    </>
  );
};

export default NotificationsSettings;