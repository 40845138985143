import React, { useState } from 'react';
import Button from '../../../components/Button';
import PasswordInput from '../../../components/Password';
import { ButtonsContainer, Title } from '../../AdminPanel';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { Block, Content, StyledForm } from '..';

const SecuritySettings: React.FC = () => {  
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    tfa: '',
    securityQuestion1: '',
    securityQuestion2: ''
  });


  const submitAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ formData })
  };

  return (
    <>      
      <Content>
        <StyledForm onSubmit={submitAccount}>     
          <Block><Title>Change Password </Title>
          <PasswordInput
            label="Current password"
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={(value) => setFormData({ ...formData, currentPassword: value })}
          />

        <PasswordInput
            label="New password"
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={(value) => setFormData({ ...formData, newPassword: value })}
          />

        <PasswordInput
            label="Confirm new password"
            type="password"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            onChange={(value) => setFormData({ ...formData, confirmNewPassword: value })}
          />
        </Block>

        <Block>
        <Title>Two-Factor Authentication (2FA)</Title>
        <Select
            label="Method"
            name="tfa"
            value={formData.tfa}
            options={[ { label: 'Enabled via authenticator app', value: '1' } ]}
            onChange={(e) => setFormData({ ...formData, tfa: e.target.value })}
        />
        </Block>

        <Block>
        <Title>Security Questions</Title>
        <Input
            label="Security Question 1"
            name="tfa"
            value={formData.securityQuestion1}
            onChange={(value) => setFormData({ ...formData, securityQuestion1: value })}
        />
        <Input
            label="Security Question 2"
            name="tfa"
            value={formData.securityQuestion2}
            onChange={(value) => setFormData({ ...formData, securityQuestion2: value })}
        />
        </Block>
        
          <ButtonsContainer>
            <Button onClick={submitAccount}>Save</Button>
          </ButtonsContainer>
        </StyledForm>


      </Content>
    </>
  );
};



export default SecuritySettings;