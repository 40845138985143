import React, { useState } from 'react';
import { Title } from '../../../../App';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { Department } from '../../interfaces';
import { ModalInputWrapper as InputWrapper, ModalButtonContainer as ButtonContainer } from '../..';

interface AddNewDepartmentModalProps {
    onSave: (department: Department) => void;
    departments: Department[];
}

const AddNewDepartmentModal: React.FC<AddNewDepartmentModalProps> = ({ onSave, departments }) => {
    const [departmentName, setDepartmentName] = useState('');
    const [description, setDescription] = useState('');

    const [error, setError] = useState('');

    const save = () => {
        setError('')

        if (!departmentName) {
            setError('Please insert department name')
            return
        }

        if (departments.find(({ name }) => name === departmentName)) {
            setError('Please insert unique department name')
            return
        }

        const department = {
            name: departmentName,
            details: description,
        }

        onSave(department)
    }

    const onDepartmentInputChange = (value: string) => {
        setError('')
        setDepartmentName(value)
    }

  return (
    <>      
      <Title>Create new department</Title>
      <div>
        <InputWrapper >
            <label htmlFor='name'>Department name</label>
            <Input name='name' value={departmentName} onChange={onDepartmentInputChange} error={error} />
        </InputWrapper>
        <InputWrapper >
            <label htmlFor='description'>Description</label>
            <Input name='desc' textarea value={description} onChange={(value) => setDescription(value)} />
        </InputWrapper>
        <ButtonContainer>
            <Button onClick={save}>Save</Button>
        </ButtonContainer>
      </div>
    </>
  );
};

export default AddNewDepartmentModal;