import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { User, useUser } from '../../context/UserContext';

import StyledInput from '../../components/Input';
import PasswordInput from '../../components/Password';
import Button from '../../components/Button';
import InfoBox from '../../components/InfoBox';
import Error from '../../components/Error';

import { colors, URLS } from '../../constants'; 
import { users } from '../../mocks';

const Login: React.FC = () => {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ error, setError ] = useState('');
    const { user, setUser } = useUser();

    const { profileApproved, profileCompleted, firstLogin, companyType } = user || {};

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            if ((companyType === 'company' && profileApproved && !firstLogin) || companyType !== 'company') {
                navigate(URLS.dashboard);
            } else if (companyType === 'company' && (!profileCompleted || !profileApproved)) {
                navigate(URLS.signupCompany);
            } else if (profileCompleted && profileApproved && firstLogin) {
                navigate(URLS.firstLogin);
            }
        }
    }, [user, navigate]);

    const handleLogin = () => {
        //fake authentication
        const user = users.find((user) => user.email === email && user.password === password) as User;

        if (user) {
            setUser(user)
        } else {
            setError('Invalid credentials');
        }
    };

    return (
        <Wrapper>
            <div>
                <Logo>
                    <img src="/assets/logo.png" alt="Ecoconnect Logo" />
                </Logo>
                <FormContainer>
                    <Form>
                    <InfoBox>
                        Enter your credentials to log in and explore the EcoConnect network of sustainable businesses.
                    </InfoBox>
                    <LoginTitle>Login</LoginTitle>
                    <StyledInput
                        label='Email'
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(value) => setEmail(value)}
                    />
                    <PasswordInput
                        label="Password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(value) => setPassword(value)}
                        forgotPasswordLink={URLS.forgotPassword}
                    />
                    {error && <Error>{error}</Error>}
                    <Button onClick={handleLogin}>Login</Button>
                </Form>
                <SignUpLink>
                    Don&apos;t have an account? <StyledLink href={URLS.signup}>Create one!</StyledLink>
                </SignUpLink>
    
                </FormContainer>
            </div>
        </Wrapper>
    );
};

export default Login;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
`;

const Form = styled.div`
  width: 350px;
  margin: 0 auto;
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const LoginTitle = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const SignUpLink = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const StyledLink = styled.a`
  color: ${colors.darkGreen};
  text-decoration: none;
  font-weight: bold;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    padding-bottom: 20px;

    img {
        width: 100%;
    }
`;