import React, { useState } from 'react';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import Modal from '../../../components/Modal';
import AddNewCategoryModal from './Modal';
import { Category } from '../interfaces';
import { RoundButtonContainer } from '..';
import { Content } from '../../../App';
interface Props {
  categories: Category[],
  setCategories: (category: Category[]) => void
}

const ProductCategories: React.FC<Props>  = ({ categories, setCategories }) => {

  const columns = [
    {   
        field: 'name',
        filter: true,
    },
    {   
      field: 'totalItems',
      filter: true,
    },
    {   
      field: 'status',
      filter: true,
    },
    {   
      field: 'createdBy',
      filter: true,
    },
    {   
      field: 'createdAt',
      filter: true,
    },
    {   
      field: 'lastUpdated',
      filter: true,
    },
    {   
      field: 'details',
      filter: true,
    },
  ]

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addCategory = (cat: Category) => {
    setCategories([ ...categories, cat ])
    setModalIsOpen(false)
  }

  return (
    <>      
      <Content>
        <Table columns={columns} rowData={categories} height={500}/>
        
        <RoundButtonContainer>
          <RoundButton onClick={() => setModalIsOpen(true)} text="Create new category" />
        </RoundButtonContainer>
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <AddNewCategoryModal onSave={addCategory} categories={categories} />
      </Modal>
    </>
  );
};

export default ProductCategories;