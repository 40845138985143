import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { SupplierDocument } from '../interfaces';

interface UserContextType {
  user?: User | null;
  setUser: (user: User) => void;
  logout?: () => void;
}

export interface User {
  companyName: string;
  password: string;
  companyType: string;
  email: string;
  firstname: string;
  lastname: string;
  phone?: string;
  userCity?: string;
  userRegion?: string;
  userZip?: string;
  userCountry?: string;
  preferredCommunicationMethod?: string;
  overview?: string;
  mission?: string;
  industry?: string;
  productsOffered?: string;
  practiceDescription?: string;
  certifications?: string;
  supportingDocs?: SupplierDocument[]
  logoBlobString?: string;
  headquartersLocation?: string;
  address?: string;
  companyZip?: string;
  companySize?: string;
  website?: string;
  socials?: string;
  profileCompleted?: boolean;
  profileApproved?: boolean;
  firstLogin?: boolean;
  settings?: {
    general: {
      language?: string;
      currency?: string;
      timeZone?: string;
      dateFormat?: string;
      unit?: string;
    },
    notifications: {
      email: {
        newMessages: 'enabled' | 'disabled';
        rfq: 'enabled' | 'disabled';
        invitations: 'enabled' | 'disabled';
      }
    }
  }
}

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [userData, setUserData] = useState<User | null>();

  const saveUserToCookie = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
  }

  const setUser = (user: User) => {
    setUserData(user);
    saveUserToCookie(user);
  }

  const getUserFromCookie = (): User | undefined => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : undefined;
  }

  const logout = () => { 
    localStorage.removeItem('user');
    setUserData(null)
  }

  useEffect(() => {
    const user = getUserFromCookie();
    if (user) {
      setUserData(user);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user: userData, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a AppProvider');
  }
  return context;
};
